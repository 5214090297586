export enum ApplicationStepName {
  ExitSurvey = 'Exit Survey',
  YourName = 'Your Name',
  YourContact = 'Your Contact',
  AboutYou = 'About You',
  YourAddress = 'Your Address',
  YourCreditReport = 'Your Credit Report',
  CreatingOffer = 'Creating Offer',
  HardOffer = 'Hard Offer',
  PayOffPlan = 'Pay Off Plan',
  PlanSent = 'Plan Sent',
  LoanAmountRange = 'Loan Amount Range',
  PreQualificationDecline = 'PreQualification Decline',
  CreditScoreRange = 'Credit Score Range',
  Location = 'Location',
  EmployersName = 'EmployerName',
  PayrollSystem = 'PayrollSystem',
  PayrollSuccessConnection = 'Payroll Success Connection',
  SecondEmployer = 'Second Employer',
  ArgyleLinkNotSupported = 'Argyle Link Is Not Supported',
  ConfirmLoan = 'Confirm Loan',
  OfferStatus = 'Offer Status',
  EmailNotificationAlert = 'Email Notification Alert',
  DocuSignSignature = 'DocuSignSignature',
  RepaymentMethod = 'Repayment Method',
  SetupDeduction = 'Setup Deduction',
  SetupViaPayroll = 'Setup Via Payroll',
  SetupViaHr = 'Setup Via HR',
  VerifyPayrollDeduction = 'Verify Payroll Deduction',
  FirstDeduction = 'First Deduction',
  FundsSent = 'Funds Sent',
  BankAccount = 'Bank Account',
  FileUpload = 'File Upload',
  NextSteps = 'Next Steps',
  Plaid = 'Plaid',
  PlaidCheckingAccounts = 'Plaid Checking Accounts',
  OutOfState = 'Out of State',
  Offer = 'Offer',
  PreQualification = 'PreQualification',
  YourBirthDate = 'BirthDate',
  YourPaySchedule = 'PaySchedule',
  YourEmployer = 'YourEmployer',
  VerifyDetailsIntro = 'VerifyDetailsIntro',
  SetupAccount = 'SetupAccount',
  VerificationSteps = 'VerificationSteps',
  YourTotalIncome = 'YourTotalIncome',
  Feedback = 'Feedback',
  VerifyingApplication = 'Verifying Application',
  Alloy = 'Alloy',
  YourGoal = 'YourGoal',
  OpenDDADone = 'Open DDA Done',
  CardApplied = 'Card Applied',
  Error = 'Error',
  // Financial Checkup
  YourPhoneNumber = 'Your Phone Number',
  VerifyPhoneNumber = 'Verify Phone Number',
  YourEmail = 'Your Email',
  YourIncome = 'Your Income',
  YourSavings = 'Your Savings',
  YourFinances = 'Your Finances',
  YourSSN = 'Your Social Security Number',
  HowItWorks = 'How It Works',
  MethodAuth = 'Method Auth',
  Analyzing = 'Analyzing',
  // Card
  CardInfo = 'Card Info',
  CardApplication = 'Your Card Application',
  CardApplicationSuccessful = 'Card Application Successful',
  CardNextSteps = 'Card Next Steps',
  XSellLoader = 'Loading',
  ReferralProgram = 'Referral Program',
  ErrorConsolidating = 'Error Consolidating',
  ConnectPaycheck = 'Connect Your Paycheck',
  ConnectPaycheckFeedback = 'Connect Paycheck Feedback',
  // Missed Payments
  MissedPayment = 'Missed Payment',
  MakeAPayment = 'Make A Payment',
  MissedPaymentReason = 'Missed Payment Reason',
  Authorization = 'Authorization',
  WaitingAuthorization = 'Waiting Authorization',
  MakingPayment = 'Making Payment',
  PaymentInitiated = 'Payment Initiated',
  PaymentFailed = 'Payment Failed',
  // Student Loan
  StudentLoanEmployerType = 'Student Loan Employer Type',
  StudentLoanNonProfitStartDate = 'Student Loan Non Profit Start Date',
  StudentLoanMohela = 'Student Loan Mohela',
  StudentLoanDependentsQuantity = 'Student Loan Dependents Quantity',
  StudentLoanNotEnrolled = 'Student Loan Not Enrolled',
  StudentLoanPaymentSituations = 'Student Loan Payment Situations',
  StudentLoanSavings = 'Student Loan Savings',
  StudentLoanBookAppointment = 'Student Loan Book Appointment',
  StudentLoanBookAdvisor = 'Student Loan Book Advisor',
  StudentLoanNotEligible = 'Student Loan Not Eligible',
  StudentLoanScheduleDetails = 'Student Loan Schedule Details',
  StudentLoanSaveRepaymentPlan = 'Student Loan Save Repayment Plan',
  StudentLoanApplyOptions = 'Student Loan Apply Options',
  StudentLoanApplyOnYourOwn = 'Student Loan Apply on Your Own',
  StudentLoanApplyWithUs = 'Student Loan Apply with Us',
  StudentLoanApplySubmit = 'Student Loan Apply Submit',
  StudentLoanApplyPaymentResult = 'Student Loan Apply Payment Result',
  StudentLoanApplyStepsIntro = 'Student Loan Apply Steps Intro',
  StudentLoanApplySteps = 'Student Loan Apply Steps',
  StudentLoanApplyStepsCompleted = 'Student Loan Apply Steps Completed',
  StudentLoanCheckForgiveness = 'Student Loan Check Forgiveness',
  StudentLoanComeBackWhenDue = 'Student Loan Come Back When Due',

  // Debt Consolidation
  OfferAvailable = 'Offer Available',
  ImportBalance = 'Import Balance',
  DebtConsolidationSummary = 'Debt Consolidation Summary',
  DebtConsolidationEarlyAccess = 'Debt Consolidation Early Access',
  DebtConsolidationWaitlist = 'Debt Consolidation Waitlist',
}
