import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { ReactComponent as PlanneryLogo } from 'images/plannery-logo.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import styles from './Header.module.scss';

const greetingLabel = (firstName: string) => {
  const currentHour = new Date().getHours();
  let label = '';
  if (currentHour < 12 && currentHour >= 5) {
    label = `Good morning`;
  }
  if (currentHour >= 12 && currentHour < 17) {
    label = `Good afternoon`;
  }
  if (currentHour >= 17) {
    label = `Good evening`;
  }

  return firstName ? `${label} ${firstName}` : `${label}!`;
};

interface HeaderProps {
  totalDebt?: number;
  hideTotalDebt?: boolean;
}

const Header = ({ totalDebt, hideTotalDebt = false }: HeaderProps) => {
  const { application } = useSelector(getApplicationData);

  const firstName = application?.borrowerFirstName ?? '';

  return (
    <div
      className={clsx(styles.header, {
        [styles.aligned]: hideTotalDebt,
      })}
    >
      <PlanneryLogo className={styles.logo} />

      <p className={styles.greeting}>{greetingLabel(firstName!)}</p>

      {!hideTotalDebt && (
        <div className={styles.headerLabel}>
          <p>{firstName}, your Total Debt is:</p>
          <p className={styles.debtValue}>{formatMonetaryAmount(totalDebt)}</p>
        </div>
      )}
    </div>
  );
};

export default Header;
