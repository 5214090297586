import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getApplicationData } from 'selectors/getApplicationData';
import FormContainer from 'components/LoanForm/FormContainer';
import { StepComponent } from 'components/Steps/stepUtils';
import Button from 'components/Button';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { updateIdentityData, getApplicationData as getApplicationDataThunk } from 'thunks';
import CountrySelect from 'components/CountrySelect';
import NumberInput from 'components/NumberInput';
import { CreditReportVariable } from 'enums/LoanFormVariables';
import { getCreditReportData } from 'selectors/creditReport';
import { NON_DIGITS_PATTERN } from 'components/Input/validation';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { UpdateVerificationDataResult } from 'enums/FlowNextResults';

import styles from './VerifyIdentity.module.scss';

const SOCIAL_SECURITY_LENGTH_PATTERN = /^.{9}$/;

enum CreditReportInputLabel {
  SocialSecurityNumber = 'Social Security number',
}

const VerifyIdentity = ({ handleNext }: StepComponent): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { borrowerCitizenship, borrowerPersonalIdNumberVerificationAttempted } = application;

  const defaultValues = useSelector(getCreditReportData);

  const [citizenship, setCitizenship] = useState<string | null>(borrowerCitizenship!);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isCitizenshipValid = () => !!citizenship;

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(CreditReportVariable.SocialSecurityNumber, {
      required: borrowerPersonalIdNumberVerificationAttempted
        ? false
        : getMessageForRequiredFields(CreditReportInputLabel.SocialSecurityNumber),
      pattern: {
        message: getMessageForInvalidFields(CreditReportInputLabel.SocialSecurityNumber),
        value: SOCIAL_SECURITY_LENGTH_PATTERN,
      },
    });
  }, [register, watcher]);

  const submit = async () => {
    if (!citizenship) {
      return;
    }

    setIsSubmitting(true);
    await dispatchWithUnwrap(
      updateIdentityData({
        applicationId: application!.id,
        citizenship,
        socialSecurityNumber: watcher[CreditReportVariable.SocialSecurityNumber],
      }),
    );
    await dispatchWithUnwrap(getApplicationDataThunk(application!.id));
    setIsSubmitting(false);

    analytics.track('Verification Details Submitted');
    handleNext(UpdateVerificationDataResult.Continue);
  };

  return (
    <FormContainer
      title="Verify Identity"
      subtitle="Please confirm your Social Security number matches the one we have on file."
      className={styles.formContainer}
    >
      <div className={styles.fieldWithStatus}>
        <NumberInput
          label={CreditReportInputLabel.SocialSecurityNumber}
          className={styles.formInput}
          disabled={borrowerPersonalIdNumberVerificationAttempted}
          placeholder={borrowerPersonalIdNumberVerificationAttempted ? '***-**-****' : 'XXX-XX-XXXX'}
          format="###-##-####"
          name={CreditReportVariable.SocialSecurityNumber}
          errorMessage={errors[CreditReportVariable.SocialSecurityNumber]?.message}
          onChange={(event) => {
            setValue(CreditReportVariable.SocialSecurityNumber, event.target.value.replace(NON_DIGITS_PATTERN, ''));
            trigger(event.target.name as CreditReportVariable);
          }}
          value={watcher[CreditReportVariable.SocialSecurityNumber]}
          dataNeuroLabel="ssn"
        />
        {borrowerPersonalIdNumberVerificationAttempted && (
          <div className={styles.verifiedContainer}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Submitted for Verification</p>
          </div>
        )}
      </div>

      <CountrySelect
        label="Country of citizenship"
        onChange={(country) => setCitizenship(country)}
        value={citizenship!}
        placeHolder="Select Country"
        className={styles.countrySelect}
      />
      <Button
        isLoading={isSubmitting}
        disabled={!isCitizenshipValid() || !isValid}
        className={styles.button}
        onClick={submit}
      >
        Submit
      </Button>
    </FormContainer>
  );
};

export default VerifyIdentity;
