import React from 'react';
import { createDocuSignEnvelope } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { ReactComponent as SignIcon } from 'images/sign-icon.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';
import FormNavigation from 'components/FormNavigation';
import { CurrentFlow } from 'enums/CurrentFlow';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';

import styles from './DocuSign.module.scss';

const DocuSign = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const { isLoading } = useSelector((state: RootState) => state.docuSignEnvelope);

  const handleCreateDocuSignEnvelopment = async () => {
    if (application) {
      const applicationId = application.id;

      let document: DocuSignDocument;
      switch (application.currentFlow) {
        case CurrentFlow.DebtConsolidation:
        case CurrentFlow.FinancialCheckup:
        case undefined: // application with status undefined are in the debt consolidation flow
          document = DocuSignDocument.LoanAgreement;
          break;
        default:
          throw Error('Unsupported flow');
      }

      window.location.replace(
        await dispatchWithUnwrap(
          createDocuSignEnvelope({
            applicationId,
            windowLocationHref: window.location.href,
            document,
          }),
        ),
      );
    }
  };

  return (
    <>
      <FormNavigation showBackLink={false} title="Sign Agreement" />
      <FormContainer
        className={styles.formContainer}
        icon={<SignIcon />}
        title={<>Your&nbsp;loan&nbsp;is&nbsp;approved! Just&nbsp;one&nbsp;last&nbsp;step...</>}
        subtitle={
          <>
            <p>
              Click "Sign Loan Agreement" below to be redirected to DocuSign to review important disclosures and sign
              the loan agreement.
            </p>
            <p>
              After you sign, your funds will be directly deposited into your checking account within 3 business days.
            </p>
          </>
        }
      >
        <Button className={styles.button} onClick={handleCreateDocuSignEnvelopment} isLoading={isLoading}>
          Sign Loan Agreement
        </Button>
      </FormContainer>
    </>
  );
};

export default DocuSign;
