import React, { FC } from 'react';
import { HardOfferTotal } from 'handlers/applicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import { RootState } from 'handlers';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';

import styles from './CompareSavings.module.scss';

interface CompareSavingsProps {
  currentDebt: HardOfferTotal;
  planneryLoan: HardOfferTotal;
  hasOriginationFee: boolean;
}

const SavingsTitles = ['Debt to Consolidate', 'Plannery Health Worker Loan']; // TODO: Healthcare Employee Loan

const CompareSavings: FC<CompareSavingsProps> = ({ currentDebt, planneryLoan, hasOriginationFee }): JSX.Element => {
  const { isLoading } = useSelector((state: RootState) => state.applicationData);

  return (
    <div className={styles.summaryContainer}>
      {[currentDebt, planneryLoan].map((loan, index) => {
        const { monthlyPayment, financeCharge, term, apr } = loan ?? {};

        return (
          <div key={`${SavingsTitles[index].trim()}-${financeCharge}-${index}`} className={styles.summaryCard}>
            <div className={styles.cardTitle}>{SavingsTitles[index]}</div>

            {isLoading ? (
              <Loader color="#9d86f9" size={68} />
            ) : (
              <>
                <div className={styles.amountDetails}>
                  <div className={styles.amount}>{formatMonetaryAmount(monthlyPayment!) ?? 'N/A'}</div>
                  <div className={styles.amountLabel}>Per Month</div>
                </div>
                <div className={styles.amountDetails}>
                  <div className={styles.amount}>{apr ? `${apr}%` : 'N/A'}</div>
                  <div className={styles.amountLabel}>{index === 0 ? 'Est. APR' : 'APR'}</div>
                </div>
                <div className={styles.amountDetails}>
                  <div className={styles.amount}>{formatMonetaryAmount(financeCharge ?? 0) ?? 'N/A'}</div>
                  <div className={styles.amountLabel}>
                    {index === 1 && hasOriginationFee ? 'Interest + Fees' : 'Total Interest'}
                  </div>
                </div>
                <div className={styles.amountDetails}>
                  <div className={styles.amount}>{term ?? 'N/A'}</div>
                  <div className={styles.amountLabel}>Months</div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CompareSavings;
